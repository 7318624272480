<div class="md-spacer"></div>

<div fxLayout="row" fxLayoutAlign="center">
  <div fxFlex="100%" align="center">
    <img src={{message.icon}}>
  </div>
</div>

<div fxLayout="row" fxLayoutAlign="center">
  <div align="center" fxFlex="80%">
    <h3>{{message.msg}}</h3>
    <ng-container *ngIf="data.remainingTries">
      <div>Remaining tries: {{message.remainingTries}}</div>
      <countdown #cd [config]="config" (event)="handleEvent($event)"></countdown>
    </ng-container>
  </div>
</div>


<div class="md-spacer"></div>

<div fxLayout="row"
     fxLayoutAlign="center"
     *ngIf="message.isDisplayBtn && data.messageType !== 'LOGOUT_ATTEMPT'">
  <button fxFlex="50%"
          fxFlex.lt-md="80%"
          type="button"
          mat-raised-button
          (click)="submit()">{{ message.actionLabel }}
  </button>
</div>

<div fxLayout="row"
     fxLayoutAlign="center"
     fxLayoutGap="10px"
     *ngIf="message.isDisplayBtn && data.messageType === 'LOGOUT_ATTEMPT'">
  <button fxFlex="35%"
          fxFlex.lt-md="80%"
          type="button"
          mat-raised-button
          (click)="cancel()">Cancel
  </button>
  <button fxFlex="35%"
          fxFlex.lt-md="80%"
          type="button"
          mat-raised-button
          (click)="submit()">{{ message.actionLabel }}
  </button>
</div>

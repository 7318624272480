<div fxLayout="column" mat-dialog-content>
  <i class="material-icons" fxLayoutAlign="end" (click)="close()"><span class="close-btn">close</span></i>
  <div fxFlex="100%" fxLayoutAlign="center">
    <h2 class="title-text">TERMS AND CONDITIONS</h2>
  </div>
  <div fxLayout="row" fxLayout.lt-sm="column">
    <span fxFlex="100%" style="text-align: center">
      <p>
        Welcome to Singaship.ph’s Examination Portal. If you continue to browse and use this website, you are agreeing
        to comply with and be bound by the following terms and conditions of use, which together with our privacy
        policy govern Singa Ship Management Phils., Inc relationship with you in relation to this website.
      </p>
      <p>
        The term “Singa Ship” or "Singa” or "us" or "we" refers to the owner of the website whose registered office
        address is 21/F BDO Plaza 8737 Paseo de Roxas, Makati City 1209, Philippines.
        The term "you" refers to the user or viewer of our website.
      </p>
      <p>
        The use of this website is subject to the following terms of use:
      </p>
      <ul>
        <li>
          The content of the pages of this website is for the completion of your application with us.
          It is subject to change without notice.
        </li>
        <li>
          Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness,
          performance, completeness or suitability of the information and materials found or offered on this website
          for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or
          errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted
          by law.
        </li>
        <li>
          Your use of any information or materials on this website is entirely at your own risk, for which we shall not
          be liable. It shall be your own responsibility to ensure that any products, services or information available
          through this website meet your specific requirements.
        </li>
        <li>
          This website contains material which is owned by or licensed to us. This material includes, but is not limited
          to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance
          with the copyright notice, which forms part of these terms and conditions.
        </li>
        <li>
          All trademarks reproduced in this website which are not the property of, or licensed to, the operator are
          acknowledged on the website.
        </li>
        <li>
          Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offence.
        </li>
        <li>
          Your responsible use of this website is highly encouraged for purposes of complying with the provisions of
          Republic Act No. 10175, otherwise known as the Cybercrime Prevention Act of 2012, and Republic Act No. 10173,
          otherwise known as the Data Privacy Act of 2012, and other pertinent laws and regulations governing privacy
          and security of internet users and netizens.
        </li>
        <li>
          From time to time this website may also include links to other websites. These links are provided for your
          convenience to provide further information. They do not signify that we endorse the website(s). We have no
          responsibility for the content of the linked website(s).
        </li>
        <li>
          You may not create an external link to this website or document without Singa Ship Management, Phils.,
          Inc.’s prior written consent.
        </li>
        <li>
          Your use of this website and any dispute arising out of such use of the website is subject to the laws of
          "the Republic of the Philippines".”
        </li>
      </ul>
    </span>
  </div>
  <div class="sm-spacer"></div>

</div>

import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable, Injector} from '@angular/core';
import { LocalStorage } from '../utils/local-storage.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private localStorage: LocalStorage;

  constructor(private injector: Injector) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.localStorage = this.injector.get(LocalStorage);
    const idToken = this.localStorage.getItem('id_token');
    const url = req.url.toString();

    if (this.isValidPath(url)) {
      req = req.clone({
        setHeaders: {
          Authorization: `${idToken}`
        }
      });
    }

    return next.handle(req);
  }

  isValidPath(path: string): boolean {
    const paths = [
      '/exam',
      '/exam/invalidate',
      '/exam/upload-attachment',
      '/logout',
      '/auth/refresh-token',
      '/**'
    ];

    return paths.filter(p => path.includes(p)).length > 0;
  }

}

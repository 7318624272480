export class Spiels {

  // dialogs
  // exam success
  public static SUCCESS_FINISH_EXAM = 'Thank you for your time. Your exam will be checked and you will be notified of your results.';
  
  // exam error
  public static SERVER_ERROR = 'An error has occurred in the system. You will be notified of your new exam schedule.';
  public static INTERNET_CONNECTION_ERROR = 'The system has detected your offline activity or internet connectivity issues. This exam is invalidated and you will be notified of your new exam schedule.';
  public static TIME_IS_UP_ERROR = 'Time is up.';
  public static INVALID_EXAM_LINK = 'This exam link is invalid.';
  public static INVALID_EXAM_CODE = 'Please check your email for your scheduled exam information and link.';
  public static ACCESS_EXAM_LINK_BEFORE = (schedule: string) => `Your scheduled exam is at ${schedule}. You may access it 15 minutes before your scheduled exam.`;
  public static ACCESS_EXAM_LINK_AFTER = (schedule: string) => `Your scheduled exam was at ${schedule}. Your exam is invalidated. Please check your email for the new exam schedule.`;

  // exam loading next question
  public static LOADING_NEXT_QUESTION = 'Loading the next question...';

  // exam loading final submission
  public static LOADING_SUBMIT_EXAM = 'Submitting the exam...';

  // webcam error
  public static WEBCAM_START_ERROR = 'Please ensure that your web camera is open and working before proceeding with the exam.';
  public static WEBCAM_STOP_ERROR = 'Your webcam has stopped working, please make sure your camera is open.';
  public static WEBCAM_MAX_TIME_ERROR = 'You have exceeded the maximum amount of time to reopen your camera. A new exam schedule will be sent out to your email address.';
  public static WEBCAM_MAX_RETRIES_ERROR = 'You have reached the maximum amount of tries (3) with ensuring your camera works. This invalidates your exam and we will email you with your new exam schedule and link.';
  public static WEBCAM_CAPTURE_ERROR = 'We were not able to capture your photo. This will result in the invalidation of your exam. We will email you with your new exam schedule and link.';

  // webcam check
  public static WEBCAM_CHECK = 'Checking camera...';
  public static WEBCAM_DETECTED = 'Camera detected.';

  // generic error
  public static GENERIC_SERVER_ERROR = 'An error has occurred in the system.';

  // page refresh/close exam popup
  public static EXAM_CLOSE_REFRESH_ERROR = 'Exam is interrupted. This will result in the invalidation of your exam. We will email you with your new exam schedule and link.';

  public static IDLE_ERROR = 'You\'ve been idle for too long. New exam schedule will be sent to you.';

  // logout attempt
  public static LOGOUT_ATTEMPT_ERROR = 'Logging out during this time will invalidate your exam.';

  // button label
  public static CONFIRM_BTN_LABEL = 'Got It';
  public static OKAY_BTN_LABEL = 'Okay';
}

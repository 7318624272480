import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {AuthService} from 'src/app/services/auth.service';
import {Spiels} from '../../utils/Spiels';
import {ExamService} from '../../services/exam.service';
import {BaseComponent} from '../../shared/base.component';
import {LocalStorage} from '../../utils/local-storage.service';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.css']
})
export class MessageDialogComponent extends BaseComponent implements OnInit {

  message: any = {
    icon: 'assets/icons/error-icon.png',
    msg: '',
    actionLabel: Spiels.CONFIRM_BTN_LABEL,
    isDisplayBtn: true
  };

  config = {
    leftTime: 60,
    format: 'mm:ss'
  };

  constructor(public dialogRef: MatDialogRef<MessageDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private authService: AuthService,
              private examService: ExamService,
              private localStorage: LocalStorage) {
    super();
  }

  ngOnInit() {
    switch (this.data.messageType) {
      case 'SUCCESS_FINISH_EXAM': {
        this.message = {
          ...this.message,
          icon: 'assets/icons/success-icon.png',
          msg: Spiels.SUCCESS_FINISH_EXAM
        };
        break;
      }
      case 'INVALID': {
        this.message = {
          ...this.message,
          msg: Spiels.INVALID_EXAM_LINK
        };
        break;
      }
      case 'INVALID_EXAM_CODE': {
        this.message = {
          ...this.message,
          msg: Spiels.INVALID_EXAM_CODE
        };
        break;
      }
      case 'EXAM_CLOSE_OR_REFRESH_PAGE': {
        this.message = {
          ...this.message,
          msg: Spiels.EXAM_CLOSE_REFRESH_ERROR
        };
        break;
      }
      case 'EXAM_EARLY': {
        this.message = {
          ...this.message,
          msg: Spiels.ACCESS_EXAM_LINK_BEFORE(this.data.schedule)
        };
        break;
      }
      case 'EXAM_LATE': {
        this.message = {
          ...this.message,
          msg: Spiels.ACCESS_EXAM_LINK_AFTER(this.data.schedule)
        };
        break;
      }
      case 'SERVER_ERROR': {
        this.message = {
          ...this.message,
          msg: Spiels.SERVER_ERROR,
        };
        break;
      }
      case 'INTERNET_CONNECTION_ERROR': {
        this.message = {
          ...this.message,
          msg: Spiels.INTERNET_CONNECTION_ERROR,
        };
        break;
      }
      case 'TIME_IS_UP_NEXT_QUESTION': {
        this.message = {
          ...this.message,
          icon: 'assets/icons/loader.png',
          msg: `${Spiels.TIME_IS_UP_ERROR} ${Spiels.LOADING_NEXT_QUESTION}`,
          isDisplayBtn: false
        };
        break;
      }
      case 'TIME_IS_UP_SUBMIT_EXAM': {
        this.message = {
          ...this.message,
          icon: 'assets/icons/loader.png',
          msg: `${Spiels.TIME_IS_UP_ERROR} ${Spiels.LOADING_SUBMIT_EXAM}`,
          isDisplayBtn: false
        };
        break;
      }
      case 'LOADING_NEXT_QUESTION': {
        this.message = {
          ...this.message,
          icon: 'assets/icons/loader.png',
          msg: Spiels.LOADING_NEXT_QUESTION,
          isDisplayBtn: false
        };
        break;
      }
      case 'LOADING_SUBMIT_EXAM': {
        this.message = {
          ...this.message,
          icon: 'assets/icons/loader.png',
          msg: Spiels.LOADING_SUBMIT_EXAM,
          isDisplayBtn: false
        };
        break;
      }
      case 'WEBCAM_CHECK': {
        this.message = {
          ...this.message,
          icon: 'assets/icons/loader.png',
          msg: Spiels.WEBCAM_CHECK,
          isDisplayBtn: false
        };
        break;
      }
      case 'WEBCAM_DETECTED': {
        this.message = {
          ...this.message,
          icon: 'assets/icons/success-icon.png',
          msg: Spiels.WEBCAM_DETECTED,
          actionLabel: Spiels.OKAY_BTN_LABEL
        };
        break;
      }
      case 'WEBCAM_START_ERROR': {
        this.message = {
          ...this.message,
          icon: 'assets/icons/error-icon.png',
          msg: Spiels.WEBCAM_START_ERROR,
          remainingTries: this.data.remainingTries,
          actionLabel: Spiels.OKAY_BTN_LABEL
        };
        break;
      }
      case 'WEBCAM_STOP_ERROR': {
        this.message = {
          ...this.message,
          icon: 'assets/icons/error-icon.png',
          msg: Spiels.WEBCAM_STOP_ERROR,
          remainingTries: this.data.remainingTries,
          actionLabel: Spiels.OKAY_BTN_LABEL
        };
        break;
      }
      case 'WEBCAM_MAX_TIME_ERROR': {
        this.message = {
          ...this.message,
          icon: 'assets/icons/error-icon.png',
          msg: Spiels.WEBCAM_MAX_TIME_ERROR,
          actionLabel: Spiels.OKAY_BTN_LABEL
        };
        break;
      }
      case 'WEBCAM_MAX_RETRIES_ERROR': {
        this.message = {
          ...this.message,
          icon: 'assets/icons/error-icon.png',
          msg: Spiels.WEBCAM_MAX_RETRIES_ERROR,
          actionLabel: Spiels.OKAY_BTN_LABEL
        };
        break;
      }
      case 'WEBCAM_CAPTURE_ERROR': {
        this.message = {
          ...this.message,
          icon: 'assets/icons/error-icon.png',
          msg: Spiels.WEBCAM_CAPTURE_ERROR,
          actionLabel: Spiels.OKAY_BTN_LABEL
        };
        break;
      }
      case 'LOGOUT_ATTEMPT': {
        this.message = {
          ...this.message,
          icon: 'assets/icons/error-icon.png',
          msg: Spiels.LOGOUT_ATTEMPT_ERROR,
          actionLabel: Spiels.OKAY_BTN_LABEL
        };
        break;
      }
      case 'GENERIC_SERVER_ERROR': {
        this.message = {
          ...this.message,
          icon: 'assets/icons/error-icon.png',
          msg: Spiels.GENERIC_SERVER_ERROR,
          actionLabel: Spiels.OKAY_BTN_LABEL
        };
        break;
      }
      case 'IDLE_ERROR': {
        this.message = {
          ...this.message,
          icon: 'assets/icons/error-icon.png',
          msg: Spiels.IDLE_ERROR,
          actionLabel: Spiels.OKAY_BTN_LABEL
        };
        break;
      }
      case 'DEFAULT_ERROR': {
        this.message = {
          ...this.message,
          icon: 'assets/icons/error-icon.png',
          msg: this.data.errorMsg,
          actionLabel: Spiels.OKAY_BTN_LABEL
        };
        break;
      }
      default:
        this.message = {
          ...this.message,
          icon: '',
          msg: '',
          isDisplayBtn: false
        };
    }
  }

  submit() {
    if (this.data.messageType === 'SUCCESS_FINISH_EXAM'
        || this.data.messageType === 'EXAM_CLOSE_OR_REFRESH_PAGE'
        || this. data.messageType === 'SERVER_ERROR') {
      this.authService.logout();
    } else if (this.data.messageType === 'LOGOUT_ATTEMPT') {
      this.examService.setInvalidateExamReason('logout_attempt');
      if (window.opener) {
        window.close();
      } else {
        this.localStorage.setItem('message', 'logout_attempt_from_start_page');
      }
    } else if (this.data.messageType === 'INTERNET_CONNECTION_ERROR') {
      this.examService.setInvalidateExamReason('internet_connection_error');
      setTimeout(() => {
        if (window.opener) {
          window.close();
        }
      }, 1000);
    }

    this.dialogRef.close();
  }

  handleEvent(event) {
    if (event.left === 0) {
      this.dialogRef.close({timesUp: true});
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}

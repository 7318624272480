
import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    template: ``
})
export class BaseComponent implements OnDestroy {
    unsubscribe$ = new Subject<void>();

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-terms-and-conditions-page',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<TermsAndConditionsComponent>,
              @Inject(MAT_DIALOG_DATA) data) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }
}

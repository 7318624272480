import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Endpoints} from '../utils/Endpoints';

@Injectable({
  providedIn: 'root'
})
export class ExamService {
  httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
  };

  private examStatus = new BehaviorSubject<string>('not_started');
  private invalidateExamReason = new BehaviorSubject<string>(null);

  constructor(private httpClient: HttpClient) { }

  public setExamStatus(status: string) {
    this.examStatus.next(status);
  }

  public get getExamStatus(): Observable<string> {
    return this.examStatus.asObservable();
  }

  public setInvalidateExamReason(reason: string) {
    this.invalidateExamReason.next(reason);
  }

  public get getInvalidateExamReason(): Observable<string> {
    return this.invalidateExamReason.asObservable();
  }

  public checkExamLinkValidity(examId): Observable<ExamValidityResponse> {
    return this.httpClient.get<ExamValidityResponse>(`${Endpoints.EXAM_VALIDITY_API}/${examId}`);
  }

  public invalidateExam(examId, reason): Observable<ExamGenericResponse> {
    const params = new HttpParams().append('reason', reason);
    const httpOptions = {...this.httpOptions, params};

    return this.httpClient.get<ExamGenericResponse>(`${Endpoints.EXAM_INVALIDATE_API}/${examId}`, httpOptions);
  }

  public startExam(examId): Observable<ExamStartResponse> {
    return this.httpClient.get<ExamStartResponse>(`${Endpoints.EXAM_START_API}/${examId}`);
  }

  public getExamQuestion(examId, questionId): Observable<ExamGetQuestionResponse> {
    const params = new HttpParams().append('questionId', questionId);
    const httpOptions = {...this.httpOptions, params};

    return this.httpClient.get<ExamGetQuestionResponse>(`${Endpoints.EXAM_GET_QUESTION_API}/${examId}`, httpOptions);
  }

  public submitExamAnswer(examId, questionId, answer): Observable<ExamSubmitAnswerResponse> {
    const reqBody = {questionId, answer};

    return this.httpClient.post<ExamSubmitAnswerResponse>(`${Endpoints.EXAM_SUBMIT_ANSWER_API}/${examId}`, reqBody);
  }

  public finishExam(examId): Observable<ExamGenericResponse> {
    return this.httpClient.get<ExamGenericResponse>(`${Endpoints.EXAM_FINISH_API}/${examId}`);
  }

  public acceptTAC(examId): Observable<ExamGenericResponse> {
    return this.httpClient.get<ExamGenericResponse>(`${Endpoints.EXAM_TERMS_CONDITIONS_API}/${examId}`);
  }
}

export interface ExamValidityResponse {
  body: {
    status: string;
    reason: string;
    schedule: string;
  }
}

export interface ExamStartResponse {
  body: {
    status: string;
    questionId: string;
    totalQuestions: string;
  }
}

export interface ExamGetQuestionResponse {
  body: {
    question: string;
    timer: number;
    options: string[];
    type: string;
  }
}

export interface ExamSubmitAnswerResponse {
  body: {
    questionId: string;
  }
}

export interface ExamGenericResponse {
  body: {
    status: string;
  }
}

export interface ExamGenericResponse {
  body: {
    status: string;
  }
}

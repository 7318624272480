import {environment} from '../../environments/environment';

export class Endpoints {

  // account
  public static LOGIN_API = environment.baseUrl + '/auth/login';
  public static REFRESH_TOKEN_API = environment.baseUrl + '/auth/refresh-token';
  public static LOGOUT_API = environment.baseUrl + '/auth/logout?access_token=';

  // exam
  public static EXAM_VALIDITY_API = environment.baseUrl + '/exam';
  public static EXAM_INVALIDATE_API = environment.baseUrl + '/exam/invalidate';
  public static EXAM_START_API = environment.baseUrl + '/exam/start';
  public static EXAM_GET_QUESTION_API = environment.baseUrl + '/exam/question';
  public static EXAM_SUBMIT_ANSWER_API = environment.baseUrl + '/exam/answer';
  public static EXAM_FINISH_API = environment.baseUrl + '/exam/finish';
  public static EXAM_TERMS_CONDITIONS_API = environment.baseUrl + '/exam/terms';

  // webcam
  public static WEBCAM_UPLOAD_PHOTOS = environment.baseUrl + '/exam/upload-attachment';
}

export class Constants {
  public static EMAIL_REGEX = /^(([a-zA-Z\-_0-9]+(\.[a-zA-Z\-_0-9]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public static ALPHANUMERIC_WITH_SPECIAL_CHAR_ANSWER_REGEX = /^[,/.ña-zA-Z0-9\-\s]*$/; // hyphen, enye, space, comma, period

  public static ANSWER_MAX_LENGTH = 5000;

  // exam popup window
  public static POPUP_WINDOW_PARAMS = `width=1500,height=900,left=30`;

  // uuid regex
  public static UUID_REGEX = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
  public static NUM_REGEX = /^[0-9]*$/;

  // webcam photo content type
  public static WEBCAM_IMAGE_CONTENT_TYPE = 'image/png';

  // webcam max tries
  public static WEBCAM_MAX_TRIES = 3;

  public static REFRESH_TOKEN_TIMER = 3300000; // 55 minutes
  public static REFRESH_TOKEN_TIME_BEFORE_EXPIRY = 300000; // 5 minutes
}

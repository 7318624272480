import { HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Endpoints } from '../utils/Endpoints';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ExamService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };
        this.examStatus = new BehaviorSubject('not_started');
        this.invalidateExamReason = new BehaviorSubject(null);
    }
    setExamStatus(status) {
        this.examStatus.next(status);
    }
    get getExamStatus() {
        return this.examStatus.asObservable();
    }
    setInvalidateExamReason(reason) {
        this.invalidateExamReason.next(reason);
    }
    get getInvalidateExamReason() {
        return this.invalidateExamReason.asObservable();
    }
    checkExamLinkValidity(examId) {
        return this.httpClient.get(`${Endpoints.EXAM_VALIDITY_API}/${examId}`);
    }
    invalidateExam(examId, reason) {
        const params = new HttpParams().append('reason', reason);
        const httpOptions = Object.assign({}, this.httpOptions, { params });
        return this.httpClient.get(`${Endpoints.EXAM_INVALIDATE_API}/${examId}`, httpOptions);
    }
    startExam(examId) {
        return this.httpClient.get(`${Endpoints.EXAM_START_API}/${examId}`);
    }
    getExamQuestion(examId, questionId) {
        const params = new HttpParams().append('questionId', questionId);
        const httpOptions = Object.assign({}, this.httpOptions, { params });
        return this.httpClient.get(`${Endpoints.EXAM_GET_QUESTION_API}/${examId}`, httpOptions);
    }
    submitExamAnswer(examId, questionId, answer) {
        const reqBody = { questionId, answer };
        return this.httpClient.post(`${Endpoints.EXAM_SUBMIT_ANSWER_API}/${examId}`, reqBody);
    }
    finishExam(examId) {
        return this.httpClient.get(`${Endpoints.EXAM_FINISH_API}/${examId}`);
    }
    acceptTAC(examId) {
        return this.httpClient.get(`${Endpoints.EXAM_TERMS_CONDITIONS_API}/${examId}`);
    }
}
ExamService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ExamService_Factory() { return new ExamService(i0.ɵɵinject(i1.HttpClient)); }, token: ExamService, providedIn: "root" });

import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            if (error instanceof HttpErrorResponse) {
              const keywords = error.url.split('/');
              const source = keywords[keywords.length - 1];
              if ([401].indexOf(error.status) !== -1) {
                this.authService.removeTokens();
                location.reload(true);
              }
            }
            return throwError(error);
          }));
    }
}

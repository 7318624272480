import { Routes } from '@angular/router';
const ɵ0 = () => import("./main-layout/main-layout.module.ngfactory")
    .then(m => m.MainLayoutModuleNgFactory);
const routes = [
    {
        path: '',
        loadChildren: ɵ0
    },
];
export class AppRoutingModule {
}
export { ɵ0 };

import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {MaterialModule} from './material/material.module';
import {TermsAndConditionsComponent} from './dialog/terms-and-conditions/terms-and-conditions.component';
import {MatDialogModule} from '@angular/material';
import {MessageDialogComponent} from './dialog/message-dialog/message-dialog.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {LocalStorage} from './utils/local-storage.service';
import {HttpConfigInterceptor} from './interceptor/httpconfig.interceptor';
import {TokenInterceptor} from './interceptor/token.interceptor';
import {BaseComponent} from './shared/base.component';
import {CountdownModule} from 'ngx-countdown';
import {NgIdleModule} from "@ng-idle/core";
import {ConnectionServiceModule} from 'ng-connection-service';

@NgModule({
  declarations: [
    AppComponent,
    BaseComponent,
    TermsAndConditionsComponent,
    MessageDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    MatDialogModule,
    CountdownModule,
    NgIdleModule.forRoot(),
    ConnectionServiceModule
  ],
  entryComponents: [
    TermsAndConditionsComponent,
    MessageDialogComponent
  ],
  providers: [
    LocalStorage,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    { provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
